var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopBar',{attrs:{"title":_vm.title}},[_c('template',{slot:"actions"},[(_vm.canAddLocationTraining())?_c('v-btn',{staticClass:"mb-0",attrs:{"color":"primary","to":{ name: 'TrainingLocation Create' }}},[_vm._v(" "+_vm._s(_vm.$t("btn.add_training_location"))+" ")]):_vm._e()],1)],2),_c('v-container',{attrs:{"id":"training_location-list","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 pt-3 mb-15",attrs:{"dark":_vm.$vuetify.theme.dark,"inline":"","color":"primary","icon":"mdi-map-marker"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-card-title',{staticClass:"pt-0 pb-2"},[_vm._v(" "+_vm._s(_vm.$t("training_location.list.title"))+" ")])]},proxy:true}])},[_c('v-data-table',{staticClass:"elevation-1 my-3",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.trainingLocationList,"options":_vm.paginationOptions,"hide-default-footer":""},on:{"update:sort-by":_vm.onSortBy,"update:sort-desc":_vm.onSortDesc},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListTextFilter',{attrs:{"translation":_vm.$t('training_location.list.filter.name'),"value":_vm.filters.nameSearched},on:{"filter-list":_vm.filterByName}})],1)]}},{key:"header.type",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex text-no-wrap"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListSelectFilter',{attrs:{"translation":_vm.$t('training_location.list.filter.type'),"value":_vm.filters.typeSearched,"items":_vm.$appConfig.trainingLocationTypes,"item-text":function (item) { return item.name; }},on:{"filter-list":_vm.filterByType}})],1)]}},{key:"header.area.name",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListSelectFilter',{attrs:{"multiple":"","translation":_vm.$t('training_location.list.filter.area'),"value":_vm.filters.areaSearched,"items":_vm.listAreas,"item-text":function (item) { return _vm.$t('form.area', {
                        name: item.name,
                      }); }},on:{"filter-list":_vm.filterByArea}})],1)]}},{key:"item.fullName",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(item.lastNameContact)+" "+_vm._s(item.firstNameContact)+" ")]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"ma-2 d-flex justify-center"},[_c('v-avatar',{staticClass:"rounded-lg",attrs:{"tile":"","color":"success"}},[_c('v-icon',{on:{"click":function($event){return _vm.goToTrainingLocationForm(item)}}},[_vm._v(" mdi-pencil ")])],1),_c('v-avatar',{staticClass:"rounded-lg ml-2",attrs:{"tile":"","color":"success"}},[_c('v-icon',{on:{"click":function($event){return _vm.goToDuplicateTrainingLocationForm(item)}}},[_vm._v(" mdi-content-duplicate ")])],1),(_vm.canDeleteTrainingLocation())?_c('delete-button',{attrs:{"classes":"ml-2","text-modal":_vm.$t('training_location.list.delete_title', { name: item.name })},on:{"delete":function($event){return _vm.deleteById(item.id)}}}):_vm._e()],1)]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination-options":_vm.paginationOptions,"nb-pages":_vm.nbPages},on:{"changePaginationOptions":function($event){_vm.paginationOptions = $event}}})]},proxy:true}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }