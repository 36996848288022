

































































































































import { defineComponent, ref } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import TopBar from '@/components/organisms/o-top-bar.vue'
import { TrainingLocation } from '@/api/interfaces/traininglocation'
import { QueryParams } from '@/store/api-plateform-utils'
import {
  usePagination,
  PaginationOptions,
  parseQuery,
} from '@/composables/pagination'
import Pagination from '@/components/base/Pagination.vue'
import router from '@/router'
import { userConnectedCanAccess, userConnectedHasRole } from '@/composables/UserGrant'
import deleteButton from '@/components/molecules/m-delete-button.vue'

export default defineComponent({
  setup (props, ctx) {
    const canAddLocationTraining = () => userConnectedCanAccess('TrainingLocation Create')
    const canDeleteTrainingLocation = () => userConnectedHasRole('ROLE_DELETE_TRAINING_LOCATION')
    const query = {
      ...router.app.$route.query,
    }
    const filters = ref({
      nameSearched: query.nameSearched || '',
      typeSearched: query.typeSearched || '',
      areaSearched: query.areaSearched || '',
    })
    delete query.nameSearched
    delete query.typeSearched
    delete query.areaSearched
    const totalItems = () => {
      return ctx.root.$store.state.trainingLocationList.totalItems
    }
    const {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      }: PaginationOptions = usePagination(
    totalItems,
    parseQuery(query),
  ) as PaginationOptions

    return {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      filters,
      canAddLocationTraining,
      canDeleteTrainingLocation,
    }
  },
  name: 'TrainingLocationList',
  components: {
    deleteButton,
    TopBar,
    ListTextFilter: () => import('@/components/base/ListTextFilter.vue'),
    ListSelectFilter: () => import('@/components/base/ListSelectFilter.vue'),
    Pagination,
  },
  data () {
    return {
      title: this.$t('training_location.list.title_header'),
      headers: [
        {
          text: this.$t('training_location.list.headers.name'),
          align: 'start',
          value: 'name',
        },
        {
          text: this.$t('training_location.list.headers.type'),
          value: 'type',
        },
        {
          text: this.$t('training_location.list.headers.zipCode'),
          value: 'zipCode',
        },
        {
          text: this.$t('training_location.list.headers.city'),
          value: 'city',
        },
        {
          text: this.$t('training_location.list.headers.contact'),
          value: 'fullName',
        },
        {
          text: this.$t('training_location.list.headers.phone_number_contact'),
          value: 'phoneNumberContact',
        },
        {
          text: this.$t('training_location.list.headers.email_contact'),
          value: 'emailContact',
        },
        {
          text: this.$t('training_location.list.headers.maximum_capacity'),
          value: 'maximumCapacity',
        },
        {
          text: this.$t('training_location.list.headers.longitude'),
          value: 'longitude',
        },
        {
          text: this.$t('training_location.list.headers.latitude'),
          value: 'latitude',
        },
        {
          text: this.$t('training_location.list.headers.area'),
          value: 'area.name',
          sortable: false,
        },
        {
          text: this.$t('training_location.list.headers.actions'),
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('trainingLocationList', {
      trainingLocationList: 'list',
      loading: 'loading',
    }),
    ...mapState('area', {
      listAreas: 'list',
    }),
    baseRequest (): QueryParams {
      const request: Record<string, unknown> = {
        type: this.filters.typeSearched,
        name: this.filters.nameSearched,
        area: this.filters.areaSearched,
        ...this.paginationRequestParams,
      }
      return request as QueryParams
    },
  },
  created () {
    this.load(this.baseRequest)
  },
  watch: {
    filters: {
      handler () {
        this.paginationOptions.page = 1
        this.loadData()
      },
      deep: true,
    },
    paginationOptions: {
      handler () {
        this.loadData()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('trainingLocationList', {
      load: 'load',
      deleteById: 'deleteById',
    }),
    loadData () {
      this.addParamsToLocation()
      this.load(this.baseRequest)
    },
    addParamsToLocation () {
      let query: any = {
        ...this.filters,
        ...this.paginationOptions,
      }
      query = Object.fromEntries(
        Object.entries(query).filter(([key, value]) => !!value),
      )

      router.replace({ name: 'TrainingLocation List', query: query })
    },
    goToTrainingLocationForm (item: TrainingLocation) {
      if (item.id) {
        this.$router.push({
          name: 'TrainingLocation Edit',
          params: { idTrainingLocation: item.id.toString() },
        })
      }
    },
    goToDuplicateTrainingLocationForm (item: TrainingLocation) {
      if (item.id) {
        this.$router.push({
          name: 'TrainingLocation Duplicate',
          params: { idTrainingLocation: item.id.toString() },
        })
      }
    },
    filterByName (value: string) {
      this.filters.nameSearched = value
    },
    filterByType (value: string) {
      this.filters.typeSearched = value
    },
    filterByArea (value: string) {
      this.filters.areaSearched = value
    },
  },
  beforeRouteLeave (to, from, next) {
    if (
      to.name === 'TrainingLocation Edit' ||
      to.name === 'TrainingLocation Create' ||
      to.name === 'TrainingLocation Duplicate'
    ) {
      this.$store.commit('navigation/resetPile')
      this.$store.commit('navigation/addRoute', from)
    }
    next()
  },
})
